import React from "react";

const SignupHeader = () => {
  return (
    <div className="signup-header">
      <div className="signup-title">Welcome back</div>
      <div className="signup-desc">
        Tip: Get technical and broker support by visiting the support section
        within the app.
      </div>
    </div>
  );
};

export default SignupHeader;
