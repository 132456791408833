import React from "react";

const DialogSuccess = () => {
  return (
    <>
      <div className="signup-context-title">Almost done...</div>
      <div className="forgot-password-description">
        We&apos;ve sent an email to your mailbox. Open it up to recover your
        password.
      </div>
    </>
  );
};

export default DialogSuccess;
