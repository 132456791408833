import React, { useState } from "react";
import BackToLoginButton from "../../components/back-to-login-button";
import LoginHeader from "../../components/login-header";
import DialogSuccess from "./components/dialog-success";
import EnterEmailComponent from "./components/enter-email-component";

const ForgotPassword = () => {
  const [step, setStep] = useState("");
  const handleSetStep = (value) => {
    setStep(value);
  };
  const renderSwitch = (param) => {
    switch (param) {
      case "Success":
        return <DialogSuccess />;
      default:
        return <EnterEmailComponent handleSetStep={handleSetStep} />;
    }
  };
  return (
    <div className="signup-container">
      <LoginHeader />
      <div className="forgot-password__container">{renderSwitch(step)}</div>
      <div className="back-to-login-button-container">
        <BackToLoginButton />
      </div>
    </div>
  );
};

export default ForgotPassword;
